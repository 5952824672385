// Generated by Framer (83eb5d8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vg0jsdLZt"];

const serializationHash = "framer-yRjwf"

const variantClassNames = {vg0jsdLZt: "framer-v-1on0yqg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, logo, width, ...props}) => { return {...props, js6rJ7vx5: logo ?? props.js6rJ7vx5 ?? {src: "https://framerusercontent.com/images/30CVW3Cl1su5LeozijwSOk2Bw0.svg"}, nvD5RQl9W: link ?? props.nvD5RQl9W} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;logo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nvD5RQl9W, js6rJ7vx5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vg0jsdLZt", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={nvD5RQl9W} openInNewTab={false} smoothScroll><Image {...restProps} animate={variants} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 49, intrinsicWidth: 166, pixelHeight: 49, pixelWidth: 166, sizes: "min(166px, 100vw)", ...toResponsiveImage(js6rJ7vx5)}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1on0yqg", className, classNames)} framer-1o1xrxs`} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"vg0jsdLZt"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-yRjwf[data-border=\"true\"]::after, .framer-yRjwf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yRjwf.framer-1o1xrxs, .framer-yRjwf .framer-1o1xrxs { display: block; }", ".framer-yRjwf.framer-1on0yqg { height: 48px; overflow: visible; position: relative; text-decoration: none; width: 166px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 166
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nvD5RQl9W":"link","js6rJ7vx5":"logo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerLEI9PIK9M: React.ComponentType<Props> = withCSS(Component, css, "framer-yRjwf") as typeof Component;
export default FramerLEI9PIK9M;

FramerLEI9PIK9M.displayName = "Logo";

FramerLEI9PIK9M.defaultProps = {height: 48, width: 166};

addPropertyControls(FramerLEI9PIK9M, {nvD5RQl9W: {title: "Link", type: ControlType.Link}, js6rJ7vx5: {__defaultAssetReference: "data:framer/asset-reference,30CVW3Cl1su5LeozijwSOk2Bw0.svg?originalFilename=logo-pc.svg&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerLEI9PIK9M, [])